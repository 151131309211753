<template>
  <div class="management">
    <div class="df-row">
      <!-- 门店选择器 -->
      <div class="df-col">
        <el-select
          v-model="submitData.stores_guid"
          placeholder="请选择门店"
          size="mini"
        >
          <el-option
            v-for="item in storeList"
            :key="item.StoresGuid"
            :label="item.StoresName"
            :value="item.StoresGuid"
          >
          </el-option>
        </el-select>
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onSearchData"
          >查询</el-button
        >
      </div>

      <!-- 新增按钮 -->
      <div class="df-col">
        <el-button type="primary" size="mini" @click="onAddPage"
          >新增</el-button
        >
      </div>
    </div>

    <!-- 内容页表格 -->
    <el-table
      :data="pageList"
      border
      stripe
      :max-height="tableMaxHeight"
      style="width: 100%; margin-top: 20px"
      size="mini"
    >
      <el-table-column
        prop="PageId"
        label="页面ID"
        width="100"
      ></el-table-column>
      <el-table-column prop="PageName" label="页面名称"></el-table-column>
      <el-table-column prop="StoresName" label="所属门店"></el-table-column>
      <!-- <el-table-column prop="type" label="页面类型"></el-table-column> -->
      <el-table-column label="添加时间">
        <template slot-scope="scoped">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scoped.row.CreateTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scoped">
          {{
            util.dateFormat(
              "YYYY-mm-dd HH:MM:SS",
              new Date(scoped.row.UpdateTime * 1000)
            )
          }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scoped">
          <el-button type="primary" size="mini" @click="onEditPage(scoped.row)"
            >编辑</el-button
          >
          <el-button
            type="danger"
            :disabled="deleteDisabled"
            size="mini"
            @click="onDeletePage(scoped.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器 -->
    <el-pagination
      background
      :page-sizes="[10, 15, 20]"
      :page-size="submitData.page_size"
      :current-page="submitData.page_index"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecord"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import Wxmpdiy from "@/api/wxmpdiy.js";

export default {
  data() {
    return {
      totalRecord: 0, // 列表总数
      tableMaxHeight: "", // 表格最大高度
      submitData: { // 提交数据
        page_size: 10,
        page_index: 1,
        stores_guid: "",
      },  
      pageList: [], // 自定义内容页面列表
      storeList: [],  // 门店列表
      deleteDisabled: false,  // 删除防抖
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.getStoreSelector();
    this.getPageList();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 20 + 40 + 48 + 56);
      // console.log(clientHeight);
    },

    // 获取门店列表
    async getStoreSelector() {
      try {
        let { data } = await Wxmpdiy.getStoreSelector();
        data.unshift({
          StoresGuid: "",
          StoresName: "全部",
        });
        this.storeList = data;
      } catch (error) {
        console.log(error);
      }
    },

    // 获取页面列表
    async getPageList() {
      let { submitData } = this;

      try {
        let { data } = await Wxmpdiy.getPageList(submitData);
        this.pageList = data.list;
        this.totalRecord = data.filter.TotalRecord;
      } catch (error) {
        console.log(error);
      }
    },

    // 删除按钮点击事件
    onDeletePage(event) {
      this.deleteDisabled = true;

      this.$confirm(`删除后数据将无法恢复，确定要删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { errcode } = await Wxmpdiy.deletePage({
              page_id: event.PageId,
            });
            if (errcode == 0) {
              this.$message.success("删除成功!");
              this.getPageList();
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.deleteDisabled = false;
          }
        })
        .catch(() => {
          this.deleteDisabled = false;
        });
    },

    // 查询按钮点击事件
    onSearchData() {
      this.getPageList();
    },

    // 新增按钮点击事件
    onAddPage() {
      this.onEditPage({ PageId: 0 });
    },

    // 编辑按钮点击事件
    onEditPage(event) {
      this.$router.push({
        path: "/setting/applet/page/design",
        query: event,
      });
    },

    // 分页：每页显示多少条数据
    handleSizeChange(event) {
      this.submitData.page_index = 1;
      this.submitData.page_size = event;
      // this.initPageData();
    },

    // 分页：跳转到第几页
    handleCurrentChange(event) {
      this.submitData.page_index = event;
      // this.initPageData();
    },

    // 重置页面数据
    resetData() {
      this.submitData = {
        page_size: 10,
        page_index: 1,
        stores_guid: this.submitData.stores_guid,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.management {
  .el-pagination {
    float: right;
    margin-top: 20px;
  }
}
</style>
