import { request } from "../utils/axios"

export default {
	// 获取门店选择列表
	getStoreSelector(params = {}) {
		return request("get", "/wxmpdiy/get_store_selector", params)
	},

	// 获取页面列表
	getPageList(params = {}){
		return request("get", "/wxmpdiy/get_page_list", params)
	},

	// 获取页面初始数据
	initPageData(params = {}){
		return request("get", "/wxmpdiy/init_page_data", params)
	},

	// 保存页面数据
	savePageData(params = {}){
		return request("post", "/wxmpdiy/save_page_data", params)
	},

	// 删除页面
	deletePage(params = {}){
		return request("get", "/wxmpdiy/delete_page", params)
	},
	
	// 获取功能设置列表
	getFeatureList(params = {}){
		return request("get", "/wxmpdiy/get_feature_list", params)
	},

	// 启用功能设置
	enableFeature(params = {}){
		return request("get", "/wxmpdiy/enable_feature", params)
	},

	// 初始化功能设置数据
	initFeatureData(params = {}){
		return request("get", "/wxmpdiy/init_feature_data", params)
	},

	// 保存功能设置
	saveFeature(params = {}){
		return request("post", "/wxmpdiy/save_feature", params)
	},

	// 隐藏功能设置
	hideFeature(params = {}){
		return request("get", "/wxmpdiy/hide_feature", params)
	},

	// 获取优惠券类型列表
	getCouponTypeList(params = {}){
		return request("get", "/wxmpdiy/get_coupon_activity_selector?load=coupon", params)
	},

	// 获取优惠券批次列表
	getCouponBatchList(params = {}){
		return request("get", `/wxmpdiy/get_coupon_activity_selector?load=send&coupon_guid=${params.coupon_guid}`)
	},

	// 获取全局设置数据
	getGlobalData(params = {}){
		return request("get", `/wxmpdiy/get_global_data`, params)
	},

	// 保存全局设置数据
	saveGlobalData(params = {}){
		return request("post", `/wxmpdiy/save_global_data`, params)
	},

	// 获取可售卖会员卡
	getBuyCardSelector(params = {}){
		return request("get", `/wxmpdiy/get_buy_card_selector`, params)
	},

	// 同步功能设置
	copyFeatureTo(params = {}){
		return request("post", `/wxmpdiy/copy_feature_to`, params)
	},

	getCouponActivityDataViewer(params={}){
		return request('POST','/wxmpdiy/get_coupon_activity_data_viewer', params);
	},
}